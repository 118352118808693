import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  FormLabel,
  FormControl,
  Container,
} from "react-bootstrap";
import SelectSearch from "react-select-search";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  AdminService,
  EmailService,
  EventService,
  UserService,
  FormService,
  FileService,
} from "../../services";
import { setSelection } from "../../actions";
import CreateOrg from "./createOrg";
import CreateUser from "./createUser";
import UserView from './userView';
import OrgView from './orgView';
import { setCreateUser, setCreateOrg } from "../../actions";
import { v4 as uuidv4 } from "uuid";
import { failedPopup, successPopup } from "./Popup";
import Moment from "react-moment";

/*
    Admin view component

    This is the "smaller" block to the bottom right of the screen

    Contains views for every user or org including the user and org creation pages
*/




export default function AdminView(props) {
  useEffect(() => {
    onLoad();
  }, [props]);

  const dispatch = useDispatch((state) => state.isSelect);
  const [org, setOrg] = useState({ value: "", label: "" });
  const [orgs, setOrgs] = useState([]);

  const [groups, setGroups] = useState([]);

  const [actor, setActor] = useState({
    name: "",
    id: "",
    isOrg: "",
  });

  const [userInfo, setUserInfo] = useState({
    email: "",
    org: "",
    guid: "",
    uuid: "",
    createdAt: "",
    updatedAt: "",
    clientID: "",
    subLink: "",
    folder: "",
    eventLink: "",
    firstName: "",
    lastName: "",
    isDeleted: false,
  });

  const [userFlags, setUserFlags] = useState({
    hasWebsite: false,
    hasEmail: false,
    hasForms: false,
    hasDesign: false,
    hasFiles: false,
    hasClubchat: false,
    hasEvents: false,
    hasDatabase: false,
  });

  const [orgInfo, setOrgInfo] = useState({
    users: [],
    name: "",
    guid: "",
    formCompany: "",
    formEmail: "",
    formPassword: "",
    companyName: "",
    hasLogo: false,
    hasImage: false,
    logoUrl: "",
    backgroundUrl: "",
    randomId: "",
    clubName: "",
    firebaseDomain: "",
    groups: [],
    dbURL: "",
    projID: "",
    subLink: "",
    folder: "",
    bucket: "",
    senderID: "",
    apiKey: "",
    memberCategories: "",
    appID: "",
    s3URL: "",
    isDeleted: false,
    logoData: null,
    backData: null,
    logoPreview: null,
    backPreview: null,
  });

  // console.log(orgInfo,'===========Org info');
  const [orgFlags, setOrgFlags] = useState({
    groups: {},
    hasForms: false,
    hasDatabase: false,
    hasEmail: false,
    hasFiles: false,
  });

  const [defaultSelected, setDefaultSelected] = useState([]);

  const [userUpdatedAt, setUserUpdatedAt] = useState({});

  async function onLoad() {

    setActor({
      name: props.actor,
      id: props.actorID,
      isOrg: props.isOrg,
    });

    var tempUserUpdatedAt = {};
    await AdminService.getUsers().then((res) => {
      res.data.forEach(input => {
        tempUserUpdatedAt[input.uuid] = input.updatedAt; 
      })
    });
    setUserUpdatedAt(tempUserUpdatedAt);
    //resetActor();

    if(groups.length === 0){
      await AdminService.getGroups().then((res) => {
      console.log(groups.length)
      var tempGroups = [];
      var label = {label: "Groups", options: []};
      for(var i = 0; i < res.data.length; i++){
        label.options.push({"value": String(i + 1), "name": res.data[i].name});
      }
      tempGroups.push(label);
      setGroups(tempGroups[0].options);
    })
    }
    
    setData();
  }

  function resetActor() {
    if (props.actor !== actor.name) {
      setUserInfo({
        email: "",
        org: "",
        guid: "",
        uuid: "",
        new: false,
        createdAt: "",
        clientID: "",
        subLink: "",
        folder: "",
        eventLink: "",
      });
      setUserFlags({
        hasWebsite: false,
        hasEmail: false,
        hasForms: false,
        hasDesign: false,
        hasFiles: false,
        hasClubchat: false,
        hasEvents: false,
        hasDatabase: false,
      });
      setOrgInfo({
        users: [],
        guid: "",
        formEmail: "",
        formPassword: "",
        companyName: "",
        hasLogo: false,
        hasImage: false,
        clubName: "",
        firebaseDomain: "",
        groups: {},
        dbURL: "",
        projID: "",
        subLink: "",
        folder: "",
        bucket: "",
        senderID: "",
        apiKey: "",
        memberCategories: "",
        appID: "",
        s3URL: "",
      });
      setOrgFlags({
        groups: {},
        hasForms: false,
        hasDatabase: false,
        hasEmail: false,
        hasFiles: false,
      });

      if (props.actorID === "blank") {
      } else {
        updateInfo(props.actorID);
      }
    }
  }
  async function getOrg() {
    await AdminService.getOrgs().then((res) => {
      let prevOrgs = [];
      for (var x of res.data) {
        prevOrgs.push({
          value: x.value,
          label: x.name.replaceAll("_", " "),
        });
      }
      setOrgs(prevOrgs);
    });
  }
  async function updateInfo(id) {

    if (props.isOrg === "user") {
      // USER INFO

      // Flags
      await UserService.getUserMeta(id).then((res) => {
        setUserFlags({
          hasWebsite: res.data.hasWebsite === 1 ? true : false,
          hasEmail: res.data.hasEmail === 1 ? true : false,
          hasForms: res.data.hasForms === 1 ? true : false,
          hasDesign: res.data.hasDesign === 1 ? true : false,
          hasFiles: res.data.hasFiles === 1 ? true : false,
          hasClubchat: res.data.hasClubchat === 1 ? true : false,
          hasEvents: res.data.hasEvents === 1 ? true : false,
          hasDatabase: res.data.hasDatabase === 1 ? true : false,
        });
      });
      // Basic
      await UserService.getUser(id).then(async (res) => {
        if (res.data) {
          await AdminService.getOrg(res.data.guid).then((e) => {
            setOrg((prev) => ({
              ...prev,
              value: e.data.value,
              label: e.data.name ? e.data.name.replaceAll("_", " ") : "",
            }));
          });
          var timeTemp;
          function getFormatedDate(date) {
            let fdate = new Date(date);
            return (
              fdate.getMonth() +
              1 +
              "/" +
              fdate.getDate() +
              "/" +
              fdate.getFullYear()
            );
          }
          setUserInfo((prevState) => ({
            ...prevState,
            uuid: res.data.uuid,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            folder: res.data.folderId,
            guid: res.data.guid,
            isDeleted: res.data.deletedAt !== null ? true : false,
            updatedAt:
              res.data.updatedAt !== null
                ? getFormatedDate(res.data.updatedAt)
                : "",
          }));

          if (res.data.createdAt !== null) {
            timeTemp = new Date(
              res.data.createdAt.split("T")[0].split("-")[0],
              res.data.createdAt.split("T")[0].split("-")[1],
              res.data.createdAt.split("T")[0].split("-")[2],
              res.data.createdAt.split("T")[1].split(":")[0],
              res.data.createdAt.split("T")[1].split(":")[1],
              res.data.createdAt.split("T")[1].split(":")[2].split("Z")[0]
            )
              .toString()
              .split(" ");
          }

          setUserInfo((prevState) => ({
            ...prevState,
            email: res.data.email,
            guid: res.data.guid,
            createdAt:
              res.data.createdAt === null
                ? ""
                : timeTemp[0] +
                  ", " +
                  timeTemp[1] +
                  " " +
                  timeTemp[2] +
                  " " +
                  timeTemp[3] +
                  " " +
                  timeTemp[4],
          }));

          updateGroupInfo(res.data.guid);
        }
      });
    } else {
      // ORGANIZATION INFO

      AdminService.getOrg(id).then((res) => {
        const newId = uuidv4();
        setOrgFlags({
          groups: res.data.groups,
        });
        setOrgInfo((prevState) => ({
          ...prevState,
          hasLogo: res.data.hasLogo === 1 ? true : false,
          hasImage: res.data.hasImage === 1 ? true : false,
          companyName: res.data.name ? res.data.name.replaceAll("_", " ") : "",
          guid: id,
          groups: res.data.groups,
          randomId: newId,
          logoUrl: res.data.logoURL,
          backgroundUrl: res.data.backgroundURL,
          isDeleted: res.data.deletedAt !== null ? true : false,
          logoData: null,
          backData: null,
        }));
      });

      // ORG USERS
      await AdminService.getOrgUsers(id).then((res) => {
        setOrgInfo((prevState) => ({
          ...prevState,
          users: res.data,
        }));
      });

      // ORG FORMS
      await FormService.getOrgForms(id).then((res) => {
        if (res.data.formEmail !== "" && res.data) {
          setOrgInfo((prevState) => ({
            ...prevState,
            formCompany: res.data.formCompany,
            formEmail: res.data.formEmail,
            formPassword: res.data.formPassword,
          }));
          setOrgFlags((prevState) => ({
            ...prevState,
            hasForms: true,
            // hasDatabase: true,
          }));
        }
      });

      // ORG DATABASE
      await FormService.getOrgDatabase(id).then((res) => {
        if (res.data !== "") {
          setOrgInfo((prevState) => ({
            ...prevState,
            clubName: res.data.clubName,
            firebaseDomain: res.data.firebaseDomain,
            dbURL: res.data.dbURL,
            projID: res.data.projID,
            bucket: res.data.bucket,
            senderID: res.data.senderID,
            apiKey: res.data.apiKey,
            memberCategories: res.data.memberCategories,
            appID: res.data.appID,
            s3URL: res.data.s3URL,
          }));
          setOrgFlags((prevState) => ({
            ...prevState,
            hasDatabase: true,
          }));
        }
      });

      // ORG EMAIL
      await EmailService.getEmailInfo(id).then((res) => {
        if(res.data !== ""){
          setOrgInfo((prevState) => ({
            ...prevState,
            clientID: res.data,
          }));
          setOrgFlags((prevState) => ({
            ...prevState,
            hasEmail: true,
          }))
          }
        
      });


      // ORG FILES
      await FileService.getFilesLink(id).then((res) => {
        if(res.data.folderID !== null){
          setOrgInfo((prevState) => ({
            ...prevState,
            folder: res.data.folderID
          }));
          setOrgFlags((prevState) => ({
            ...prevState,
            hasFiles: true
          }))
        }
      })

      // // ORG EMAIL
      // await FormService.getOrgEmail(id).then((res) => {
      //   if (res.data !== ""){
      //     setOrgInfo((prevState) => ({
      //       ...prevState,
      //       clientID: res.data.clientID,
            
      //     }))
      //     setOrgFlags((prevState) => ({
      //       ...prevState,
      //       hasEmail: true,
      //     }))
      //   }
      // })

      // SUB LINK
      await EmailService.getSubLink(id).then((res) => {
        setOrgInfo((prevState) => ({
          ...prevState,
          subLink: res.data,
        }));
      });

      

      // ////////////////////////////
    }
    //setActorLoaded(true);
  }
  useEffect(() => {
    updateInfo(props.actorID);
  }, [props.deleteHappens, props.actorID]);

  useEffect(() => {
    getOrg();
  }, []);

  useEffect(() => {
    handleUpdate();
    handleOrgUpdate();
  }, [org, orgFlags, orgInfo, userInfo, userFlags]);

  async function updateGroupInfo(id) {
    // Org
    await AdminService.getOrg(id).then((res) => {
      setUserInfo((prevState) => ({
        ...prevState,
        org: res.data.name,
      }));
    });

    // Event link
    await EventService.getEventLink(id).then((res) => {
      setUserInfo((prevState) => ({
        ...prevState,
        eventLink: res.data,
      }));
    });

    // FolderID (chagned)

    await EmailService.getEmailInfo(id).then((res) => {
      setUserInfo((prevState) => ({
        ...prevState,
        clientID: res.data,
      }));
    });

    // Email
    await EmailService.getSubLink(id).then((res) => {
      setUserInfo((prevState) => ({
        ...prevState,
        subLink: res.data,
      }));
    });
  }

  const UpdateAdminView = React.useCallback(()=>{
    onLoad();
    resetActor();
    setData();
    updateInfo(props.actorID);
  },[]);
  
  React.useEffect(()=>{
    props.adminRightRef.current = UpdateAdminView
  },[]); 

  function handleButtonClick(e, x) {
    e.preventDefault();
    if (actor.isOrg === "user") {
      setUserFlags((prevState) => ({
        ...prevState,
        [x]: !userFlags[x],
      }));
    } else {
      setOrgFlags((prevState) => ({
        ...prevState,
        [x]: !orgFlags[x],
      }));
    }
  }

  function handleSelectSearch(e) {

    // this is so when you create a group in AdminLeft, we can update the groups
    AdminService.getGroups().then((res) => {
      var tempGroups = [];
      var label = {label: "Groups", options: []};
      for(var i = 0; i < res.data.length; i++){
        label.options.push({"value": String(i + 1), "name": res.data[i].name});
      }
      tempGroups.push(label);
      setGroups(tempGroups[0].options);
    })

    setDefaultSelected(e);
    var selectedGroups = [];
    for(var i in groups){
      if(e.includes(groups[i].value)){
        selectedGroups.push(groups[i].name)
      }
    }
    props.setAdminViewSelectedGroups(selectedGroups);
    if (e.length) {
      e.map((el) => parseInt(el));
      var tempGroups = orgFlags.groups;
      for(var key in orgFlags.groups){
        if(selectedGroups.includes(key)){
          tempGroups[key] = 1;
        }else{
          tempGroups[key] = 0;
        }
      }
      setOrgFlags((prevState) => ({
        ...prevState,
        groups: tempGroups,
        // hasDatabase: true,
      }));
    }
  }

  function handleInnerSelect(e, x) {
    e.preventDefault();
    dispatch(
      setSelection({
        actor: {
          name: x.name,
          id: x.uuid,
          type: "user",
        },
        user: {
          name: x.name,
          id: x.uuid,
        },
        org: {
          name: actor.name.replaceAll("_", " "),
          id: actor.id,
        },
      })
    );
  }
  function handleUpdate() {
    dispatch(
      setCreateUser({
        user: userInfo,
        userFlags: userFlags,
        new: false,
      })
    );
  }
  function handleOrgUpdate(e) {
    dispatch(
      setCreateOrg({
        org: orgInfo,
        orgFlags: orgFlags,
        new: false,
      })
    );
  }
  function handleOrgChange(e) {

    if (e !== null) {
      setOrg(e);
      setUserInfo((prevState) => ({
        ...prevState,
        org: e.label,
        guid: e.value,
      }));
    } else {
      setOrg(e);
      setUserInfo((prevState) => ({
        ...prevState,
        org: "",
        guid: "",
      }));
    }
  }

  function setData() { 
    //setDefaultSelected([]); <-- this may have caused the flickering
    var tempSelected = [];

    // make sure it is sorted
    if(orgInfo.groups){
      const orderedGroups = Object.keys(orgInfo.groups).sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }).reduce(
        (obj, key) => { 
          obj[key] = orgInfo.groups[key]; 
          return obj;
        }, 
        {}
      ); 
      for(var key in orderedGroups){
        if(orderedGroups[key] === 1){
          for(var i in groups){
            if(groups[i].name === key){
              tempSelected.push(groups[i].value)
            }
          }
        }
      }
    }
      
    setDefaultSelected(tempSelected);
  }
 
  //      custom style
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
    }),
    dropdownIndicator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    indicatorSeparator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    clearIndicator: () => ({
      padding: "0 4px",
    }),

    option: () => ({
      borderBottom: "1px solid #d3dae1",
      padding: "5px 12px",
      fontSize: "1.3rem",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? "none" : "none",
      border: state.isFocused ? "none" : "none",
      minHeight: "20px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 0 0 0",
      backgroundColor: "#ffff",
      borderRadius: "4px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
      paddingLeft: "8px",
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
      margin: "0",
      paddingTop: "0",
      paddingBottom: "0",
      width: "100%",
      backgroundColor: state.isDisabled ? "white" : "#f4f5f9",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
  };
  const handleResend = async () => {
    try {
      const res = await AdminService.resendPass(userInfo);
      if (res.data) {
        successPopup("Credentials Sent Successfully");
        updateInfo(props.actorID);
      }
    } catch (error) {
      console.log(error);
      failedPopup(error.response.data.message);
      updateInfo(props.actorID);
    }
  };
  let currentDate = new Date();
  let preDate = new Date(userInfo.updatedAt);
  let finalTime =
    Math.floor(currentDate.getTime() - preDate.getTime()) / (1000 * 3600 * 24);

  

  return (
    <div >
      
      {props.actorID === "blank" ? (
        <div>{props.isOrg === "user" ? <CreateUser /> : <CreateOrg />}</div>
      ) : (
        <div>
          
          {actor.isOrg === "user" ? (
            <UserView 
              userInfo={userInfo}
              finalTime={finalTime}
              handleUpdate={handleUpdate}
              handleResend={handleResend}
              setUserInfo={setUserInfo}
              customStyles={customStyles}
              orgs={orgs}
              org={org}
              handleOrgChange={handleOrgChange}
              userFlags={userFlags}
              handleButtonClick={handleButtonClick}
              canEdit={props.canEdit}
            />) :
            (<OrgView 
              orgInfo={orgInfo}
              setOrgInfo={setOrgInfo}
              handleOrgUpdate={handleOrgUpdate}
              defaultSelected={defaultSelected}
              handleSelectSearch={handleSelectSearch}
              groups={groups}
              handleInnerSelect={handleInnerSelect}
              userUpdatedAt={userUpdatedAt}
              orgFlags={orgFlags}
              handleButtonClick={handleButtonClick}
              canEdit={props.canEdit}
            />
            // <div className="admin-interior-view">
            //   <div className="actor-main-info">
            //     {/* ///////////////////////////////////////////////// */}
                
            //     <Row className="admin-row d-flex align-items-center">
            //       <Col className="admin-col">
            //         <h1>{userInfo.firstName + " " + userInfo.lastName}</h1>
            //       </Col>

            //       <Col className="admin-col">
            //         <p className="d-flex">
            //           {finalTime >= 7 ? (
            //             <div className={"resend-cred"} onClick={handleResend}>
            //               <i class="fa fa-repeat"></i>&nbsp; Resend Credentials{" "}
            //             </div>
            //           ) : (
            //             <div className={"cred-sent"}>
            //               <i class="fa fa-repeat"></i>&nbsp;credentials Sent{" "}
            //             </div>
            //           )}
            //           &nbsp;
            //           <span className="text-muted">{`(Last sent on ${userInfo.updatedAt})`}</span>
            //         </p>
            //       </Col>
            //     </Row>
                      
            //     <Row className="admin-row">
            //       <Col className="admin-col">
            //         <b className="info-title">First Name: </b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.firstName}
                      
            //           //defaultValue={userInfo.firstName}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               firstName: e.target.value,
            //             }));

            //             handleUpdate();
            //           }}
            //           //   onChange={(e) => setFname(e.target.value)}
            //         />
            //       </Col>

            //       <Col className="admin-col">
            //         <b className="info-title">Last Name: </b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.lastName}
            //           //defaultValue={userInfo.lastName}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               lastName: e.target.value,
            //             }));
            //             handleUpdate();
            //           }}
            //         />
            //       </Col>
            //     </Row>
            //     {/*  */}
            //     <Row className="admin-row">
            //       <Col className="admin-col">
            //         <b className="info-title">Email: </b>
            //         <input
            //           className="admin-input"
            //           type="email"
            //           value={userInfo.email}
            //           disabled
            //         />
            //       </Col>

            //       <Col className="admin-col">
            //         <b className="info-title">Organization: </b>
            //         <Select
            //           isClearable
            //           styles={customStyles}
            //           isDisabled={!props.canEdit}
            //           classNamePrefix="org-select"
            //           autoComplete="org select"
            //           options={orgs}
            //           value={org}
            //           onChange={(e) => {
            //             handleOrgChange(e);
            //             handleUpdate();
            //           }}
            //         />
            //       </Col>
            //     </Row>
            //   </div>
            //   <Container fluid className="actor-services">
            //     <Button
            //       className={userFlags.hasWebsite ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasWebsite")}
            //     >
            //       <i className="icon-user icon-w"></i>Website
            //     </Button>
            //     <Button
            //       className={userFlags.hasEmail ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasEmail")}
            //     >
            //       <i className="icon-user icon-e"></i>Email
            //     </Button>
            //     <Button
            //       className={userFlags.hasForms ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasForms")}
            //     >
            //       <i className="icon-user icon-f"></i>Forms
            //     </Button>
            //     <Button
            //       className={userFlags.hasDesign ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasDesign")}
            //     >
            //       <i className="icon-user icon-d"></i>Design
            //     </Button>
            //     <Button
            //       className={userFlags.hasFiles ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasFiles")}
            //     >
            //       <i className="icon-user icon-fl"></i>Files
            //     </Button>
            //     <Button
            //       className={userFlags.hasClubchat ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasClubchat")}
            //     >
            //       <i className="icon-user icon-c"></i>ClubChat
            //     </Button>
            //     <Button
            //       className={userFlags.hasEvents ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasEvents")}
            //     >
            //       <i className="icon-user icon-ev"></i>Events
            //     </Button>
            //     <Button
            //       className={userFlags.hasDatabase ? "btn-bc" : "btn-bd"}
            //       onClick={(e) => handleButtonClick(e, "hasDatabase")}
            //     >
            //       <i className="icon-user icon-db"></i>Database
            //     </Button>
            //   </Container>
            //   {userFlags.hasEmail && (
            //     <div className="admin-service">
            //       <b className="service-title">Email</b>
            //       <div className="service-info">
            //         <b className="info-title">Client ID:</b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.clientID}
            //           //defaultValue={userInfo.clientID}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               clientID: e.target.value,
            //             }));
            //             handleUpdate();
            //           }}
            //         />
            //       </div>
            //       <div className="service-info">
            //         <b className="info-title">Add Subscriber Link:</b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.subLink}
            //           //defaultValue={userInfo.subLink}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               subLink: e.target.value,
            //             }));
            //             handleUpdate();
            //           }}
            //         />
            //       </div>
            //     </div>
            //   )}{" "}
            //   {userFlags.hasFiles && (
            //     <div className="admin-service">
            //       <b className="service-title">Files</b>
            //       <div className="service-info">
            //         <b className="info-title">Folder ID:</b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.folder}
            //           //defaultValue={userInfo.folder}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               folder: e.target.value,
            //             }));
            //             handleUpdate();
            //           }}
            //         />
            //       </div>
            //     </div>
            //   )}{" "}
            //   {userFlags.hasEvents && (
            //     <div className="admin-service">
            //       <b className="service-title">Events</b>
            //       <div className="service-info">
            //         <b className="info-title">Events Link:</b>
            //         <input
            //           className="admin-input"
            //           type="text"
            //           value={userInfo.eventLink}
            //           //defaultValue={userInfo.eventLink}
            //           onChange={(e) => {
            //             e.persist();
            //             setUserInfo((prevState) => ({
            //               ...prevState,
            //               eventLink: e.target.value,
            //             }));
            //             handleUpdate();
            //           }}
            //         />
            //       </div>
            //     </div>
            //   )}
            // </div>
          //) : (
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // <Container fluid>   
            //   <Row className="admin-interior-view">
            //     <Col md={"12"} lg={"6"}>
            //       <h1 className="h1-a zero">
            //         {orgInfo.companyName &&
            //           orgInfo.companyName.replaceAll("_", " ")}{" "}
            //       </h1>
            //       <Row>
            //         <Col className="service-info mx-4 my-2">
            //           <b className="info-title">Name:</b>
            //           <input
            //             className="admin-input"
            //             type="text"
            //             value={orgInfo.companyName}
            //             //defaultValue={orgInfo.companyName}
            //             onChange={(e) => {
            //               setOrgInfo((prevState) => ({
            //                 ...prevState,
            //                 companyName: e.target.value,
            //               }));
            //               handleOrgUpdate();
            //             }}
            //           />
            //         </Col>
            //       </Row>
            //       <Row className="a-row">
            //         <Col className="admin-col col-little a-col">
            //           <b className="info-title">Logo: </b>
            //           {orgInfo.hasLogo === true ? (
            //             <>
            //               {orgInfo.logoData ? (
            //                 <figure className="admin-org-logo">
            //                   <img src={orgInfo.logoPreview} alt="" />
            //                 </figure>
            //               ) : (
            //                 <figure className="admin-org-logo">
            //                   <img
            //                     src={
            //                       "https://digital-range-v2.s3.amazonaws.com/organizationLogos/" +
            //                       orgInfo.logoUrl
            //                     }
            //                     alt=""
            //                   />
            //                 </figure>
            //               )}
            //               {props.canEdit && (
            //                 <button
            //                   style={{
            //                     borderRadius: "20px",
            //                     width: "20px",
            //                     height: "20px",
            //                     backgroundColor: "red",
            //                     border: "1px solid red",
            //                     color: "white",
            //                     fontSize: "10px",
            //                   }}
            //                   onClick={() => {
            //                     setOrgInfo((prevState) => ({
            //                       ...prevState,
            //                       hasLogo: false,
            //                       logoData: null,
            //                     }));
            //                     handleOrgUpdate();
            //                   }}
            //                 >
            //                   <b>X</b>
            //                 </button>
            //               )}
            //             </>
            //           ) : (
            //             <FormLabel className="a-label">
            //               <FormControl
            //                 type="file"
            //                 className="custom-file-input"
            //                 onChange={(e) => {
            //                   const data = new FormData();
            //                   data.append(
            //                     "logo",
            //                     e.target.files[0],
            //                     orgInfo.randomId
            //                   );

            //                   setOrgInfo((prevState) => ({
            //                     ...prevState,
            //                     logoData: data,
            //                     hasLogo: true,
            //                     logoPreview: URL.createObjectURL(
            //                       e.target.files[0]
            //                     ),
            //                   }));
            //                   handleOrgUpdate();
            //                 }}
            //               />
            //             </FormLabel>
            //           )}
            //         </Col>
            //         <Col className="admin-col a-col">
            //           <b className="info-title">Background Image: </b>
            //           {orgInfo.hasImage ? (
            //             <>
            //               {orgInfo.backPreview ? (
            //                 <img
            //                   className="admin-org-bg"
            //                   src={orgInfo.backPreview}
            //                   alt=""
            //                 />
            //               ) : (
            //                 <img
            //                   className="admin-org-bg"
            //                   src={
            //                     "https://digital-range-v2.s3.amazonaws.com/organizationBackgrounds/" +
            //                     orgInfo.backgroundUrl
            //                   }
            //                   alt=""
            //                 />
            //               )}
            //               &nbsp;
            //               {props.canEdit && (
            //                 <button
            //                   style={{
            //                     borderRadius: "20px",
            //                     width: "20px",
            //                     height: "20px",
            //                     backgroundColor: "red",
            //                     border: "1px solid red",
            //                     color: "white",
            //                     fontSize: "10px",
            //                   }}
            //                   onClick={() => {
            //                     setOrgInfo((prevState) => ({
            //                       ...prevState,
            //                       hasImage: false,
            //                       backData: null,
            //                     }));
            //                     handleOrgUpdate();
            //                   }}
            //                 >
            //                   <b>X</b>
            //                 </button>
            //               )}
            //             </>
            //           ) : (
            //             <FormLabel className="a-label">
            //               <FormControl
            //                 type="file"
            //                 className="custom-file-input"
            //                 onChange={(e) => {
            //                   const data = new FormData();
            //                   data.append(
            //                     "back",
            //                     e.target.files[0],
            //                     orgInfo.randomId
            //                   );
            //                   setOrgInfo((prevState) => ({
            //                     ...prevState,
            //                     backData: data,
            //                     hasImage: true,
            //                     backPreview: URL.createObjectURL(
            //                       e.target.files[0]
            //                     ),
            //                   }));
            //                   handleOrgUpdate();
            //                 }}
            //               />
            //             </FormLabel>
            //           )}
            //         </Col>
            //       </Row>
            //       <Row>
            //         <Col className="admin-col a-col">
            //           <b className="info-title">Groups: </b>
            //           <SelectSearch
            //             closeOnSelect={false}
            //             printOptions="on-focus"
            //             multiple
            //             placeholder="Select Groups"
            //             value={defaultSelected}
            //             onChange={(e) => {
            //               handleSelectSearch(e);
            //               handleOrgUpdate();
            //             }}
            //             options={groups}
            //           />
            //         </Col>
            //       </Row>
            //       <Row>
            //         <Col className="admin-col">
            //           <b className="info-title">
            //             Users:{" "}
            //             {orgInfo.users === undefined ? 0 : orgInfo.users.length}
            //           </b>
            //           <Table responsive className="org-users-table">
            //             <thead>
            //               <tr>
            //                 <th scope="col">Name</th>
            //                 <th scope="col">Last Seen</th>
            //               </tr>
            //             </thead>
            //             <tbody>

            //               {orgInfo.users &&
            //                 orgInfo.users.map((user) => (
            //                   <tr onClick={(e) => handleInnerSelect(e, user)}>
            //                     <td key={user + "-date"}>{user.name}</td>
            //                     <td>
            //                       <Moment format="M/D/YY h:mm A">
            //                         {userUpdatedAt[user.uuid]}
            //                         {/*console.log(userUpdatedAt[user.uuid])*/}
            //                       </Moment>
            //                     </td>
            //                   </tr>
            //                 ))}
            //             </tbody>
            //           </Table>
            //         </Col>
            //       </Row>
            //     </Col>

            //     <Col className="my-5" md={"12"} lg={"6"}>
            //       <div className="actor-org-services">
            //         <Button
            //           className={orgFlags.hasForms ? "btn-bc" : "btn-bd"}
            //           onClick={(e) => handleButtonClick(e, "hasForms")}
            //         >
            //           <i className="icon-user icon-f"></i>Forms
            //         </Button>
            //         <Button
            //           className={orgFlags.hasDatabase ? "btn-bc" : "btn-bd"}
            //           onClick={(e) => handleButtonClick(e, "hasDatabase")}
            //         >
            //           <i className="icon-user icon-db"></i>Database
            //         </Button>
            //       </div>
            //       {orgFlags.hasForms && (
            //         <div className="admin-service">
            //           <b className="service-title">Forms</b>
            //           <div className="service-info">
            //             <b className="info-title">Forms Company:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               defaultValue={orgInfo.formCompany}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   formCompany: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>

            //           <div className="service-info">
            //             <b className="info-title">Forms Email:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.formEmail}
            //               //defaultValue={orgInfo.formEmail}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   formEmail: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Forms Password:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.formPassword}
            //               //defaultValue={orgInfo.formPassword}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   formPassword: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //         </div>
            //       )}
            //       {orgFlags.hasDatabase && (
            //         <div className="admin-service">
            //           <b className="service-title">Database</b>
            //           <div className="service-info">
            //             <b className="info-title">Club Name:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.clubName}
            //               //defaultValue={orgInfo.clubName}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   clubName: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Firebase Auth Domain:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.firebaseDomain}
            //               //defaultValue={orgInfo.firebaseDomain}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   firebaseDomain: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Database URL:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.dbURL}
            //               //defaultValue={orgInfo.dbURL}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   dbURL: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Project ID:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.projID}
            //               //defaultValue={orgInfo.projID}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   projID: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Storage Bucket:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.bucket}
            //               //defaultValue={orgInfo.bucket}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   bucket: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Messaging Sender ID:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.senderID}
            //               //defaultValue={orgInfo.senderID}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   senderID: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">API Key:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.apiKey}
            //               //defaultValue={orgInfo.apiKey}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   apiKey: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //           <div className="service-info">
            //             <b className="info-title">Membership Categories:</b>
            //             <input
            //               className="admin-input"
            //               type="text"
            //               value={orgInfo.memberCategories}
            //               //defaultValue={orgInfo.memberCategories}
            //               onChange={(e) => {
            //                 setOrgInfo((prevState) => ({
            //                   ...prevState,
            //                   memberCategories: e.target.value,
            //                 }));
            //                 handleOrgUpdate();
            //               }}
            //             />
            //           </div>
            //         </div>
            //       )}
            //     </Col>
            //   </Row>
            // </Container>
          )}
        </div>
      )}
    </div>
  );
}