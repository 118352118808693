import React, { useState, useEffect } from 'react';
import 'firebase/compat/database';
import { Modal, Form } from 'react-bootstrap';
import '../../assets/scss/pages/_database-page.scss';
import DatabaseService from '../../services/databaseService';
import CircleLottie from '../generics/CircleLottie';
import { successPopup, failedPopup } from '../adminPages/Popup';

export default function DatabasePage(props) {
    // State variables

    // User data states
    const [allUsers, setAllUsers] = useState([]);
    const [editUserData, setEditUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        memberNumber: ""
    });
    const [newUserData, setNewUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        memberNumber: ""
    });
    const [userToDelete, setUserToDelete] = useState(null);

    // UI states
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    // Modal visibility states
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showFileModal, setShowFileModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // File handling states
    const [fileContent, setFileContent] = useState("");
    const [importFile, setImportFile] = useState(null);

    // Action states
    const [isEditing, setIsEditing] = useState(false);
    const [currentEditUserId, setCurrentEditUserId] = useState(null);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [isSavingEdit, setIsSavingEdit] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [isImportingUsers, setIsImportingUsers] = useState(false);

    // Fetch users from the database on component mount
    useEffect(() => {
        DatabaseService.getUsersDB({ guid: props.guid })
            .then(response => {
           
                setAllUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching users:", error);
                setLoading(false);
            });
    }, [props.guid]);

    // Filter users based on the search term
    const filteredUsers = allUsers.filter(user => {
        const query = searchTerm.toLowerCase();
        return (
            (user.firstName && user.firstName.toLowerCase().includes(query)) ||
            (user.lastName && user.lastName.toLowerCase().includes(query)) ||
            (user.email && user.email.toLowerCase().includes(query)) ||
            (user.memberNumber && user.memberNumber.toLowerCase().includes(query))
        );
    });

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    // Change the current page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Open the edit modal and set the current user data
    const openEditModal = (userId, userData) => {
        setCurrentEditUserId(userId);
        setEditUserData({
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            email: userData.email || "",
            memberNumber: userData.memberNumber || ""
        });
        setIsEditing(true);
    };

    // Close the edit modal and reset the current user data
    const closeEditModal = () => {
        setIsEditing(false);
        setCurrentEditUserId(null);
        setEditUserData({
            firstName: "",
            lastName: "",
            email: "",
            memberNumber: ""
        });
    };

    // Save the edited user data to the database
    const saveEdit = () => {
        if (!currentEditUserId) return;
        setIsSavingEdit(true);
        const updatedData = { ...editUserData };
        DatabaseService.updateUserDB({ guid: props.guid, uid: currentEditUserId, data: updatedData })
            .then(response => {
                setAllUsers(prevUsers => prevUsers.map(user =>
                    user.uid === currentEditUserId ? { ...user, ...updatedData } : user
                ));
                closeEditModal();
                setIsSavingEdit(false);
                successPopup(`"${editUserData.firstName} ${editUserData.lastName}" has been updated!`);
            })
            .catch(error => {
                console.error("Error updating user:", error);
                setIsSavingEdit(false);
                failedPopup(`Error updating user: ${error.message}`);
            });
    };

    // Open the delete modal and set the user to be deleted
    const openDeleteModal = (user) => {
        setUserToDelete(user);
        setShowDeleteModal(true);
    };

    // Close the delete modal and reset the user to be deleted
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setUserToDelete(null);
        setIsDeletingUser(false);
    };

    // Delete the user from the database
    const deleteUser = () => {
        if (!userToDelete) return;
        setIsDeletingUser(true);
        DatabaseService.deleteUserDB({ guid: props.guid, uid: userToDelete.uid })
            .then(response => {
                setAllUsers(prevUsers => prevUsers.filter(u => u.uid !== userToDelete.uid));
                successPopup(`"${userToDelete.firstName} ${userToDelete.lastName}" has been deleted!`);
                closeDeleteModal();
            })
            .catch(error => {
                console.error("Error deleting user:", error);
                failedPopup(`Error deleting user: ${error.message}`);
                closeDeleteModal();
            });
    };

    // Handle file upload for importing users
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const validFileTypes = [
                "text/csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ];
            if (!validFileTypes.includes(fileType)) {
                alert("Please upload a valid CSV or Excel file.");
                return;
            }
            setImportFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setFileContent(e.target.result);
                setShowFileModal(true);
            };
            reader.readAsText(file);
        }
    };

    // Add a new user to the database
    const addUser = () => {
        setIsAddingUser(true);
        DatabaseService.addUserDB({ guid: props.guid, newUserData: newUserData })
            .then(response => {
                setAllUsers(prevUsers => [...prevUsers, response.data.user]);
                setShowAddUserModal(false);
                setIsAddingUser(false);
                successPopup(`"${newUserData.firstName} ${newUserData.lastName}" has been created!`);
            })
            .catch(error => {
                console.error("Error adding user:", error);
                failedPopup(`Error adding user: ${error.message}`);
                setIsAddingUser(false);
            });
    };

    // Reset the user's password
    const resetPassword = () => {
        setIsResettingPassword(true);
        DatabaseService.sendResetPasswordEmailDB({ guid: props.guid, email: editUserData.email })
            .then(response => {
                successPopup(`Reset password email sent to ${editUserData.email}`);
                setIsResettingPassword(false);
            })
            .catch(error => {
                console.error("Error sending reset password email:", error);
                failedPopup(`Error sending reset password email: ${error.message}`);
                setIsResettingPassword(false);
            });
    };

    // Count the number of records in the uploaded file
    const countRecords = (content) => {
        const lines = content.split('\n');
        return lines.length - 1; // Subtract 1 for the header row
    };

    // Close the file modal and reset file-related state variables
    const closeFileModal = () => {
        setShowFileModal(false);
        setFileContent("");
        setImportFile(null);
        document.getElementById('file-upload-input').value = ""; // Clear the file input
    };

    // Import users from the uploaded file
    const handleImportUsers = () => {
        setIsImportingUsers(true);
        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('guid', props.guid);

        DatabaseService.importUsersDB(formData)
            .then(response => {
                if (response.status === 207) {
                    failedPopup(`${response.data.message}: ${JSON.stringify(response.data.emailsInUse)}`);
                } else {
                    successPopup(`${countRecords(fileContent)} users have been imported!`);
                }
                setAllUsers(prevUsers => [...prevUsers, ...response.data.data]);
                closeFileModal();
                setIsImportingUsers(false);
            })
            .catch(error => {
                failedPopup(`Error importing users: ${error.message}`);
                console.error("Error importing users:", error);
                setIsImportingUsers(false);
            });
    };

    return (
        <div className="page-container">
            <>
                <h1 className='page-title'>{props.orgName}</h1>
                <div className="search-section">
                    <input
                        type="text"
                        placeholder="Search"
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Default to the first page whenever the search field is updated
                        }}
                    />
                    <div className="button-group">
                        <button onClick={() => setShowAddUserModal(true)} className="btn-a btn-add">
                            Add User
                        </button>
                        <button className="btn-b btn-import" onClick={() => {
                            setFileContent(""); // Clear any file data
                            document.getElementById('file-upload-input').click();
                        }}>
                            Import Users
                        </button>
                    </div>
                    <input
                        type="file"
                        id="file-upload-input"
                        className="file-upload-input"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                        accept=".csv, .xlsx, .xls"
                    />
                </div>

                <div className="users-table-container">
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th className="table-header">First Name</th>
                                <th className="table-header">Last Name</th>
                                <th className="table-header">Email</th>
                                <th className="table-header">Member Number</th>
                                <th className="table-header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={5}>
                                        <div className="table-loader">
                                            <CircleLottie />
                                        </div>
                                    </td>
                                </tr>
                            ) : currentUsers && currentUsers.length > 0 ? (
                                currentUsers.map((user) => (
                                    <tr className="table-row" key={user.uid}>
                                        <td className="table-cell">{user.firstName || ""}</td>
                                        <td className="table-cell">{user.lastName || ""}</td>
                                        <td className="table-cell">{user.email || ""}</td>
                                        <td className="table-cell">{user.memberNumber || ""}</td>
                                        <td className="table-cell action-cell">
                                            <button
                                                className="btn btn-edit btn-db-edit"
                                                onClick={() => openEditModal(user.uid, user)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="icon" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M15.232 5.232l3.536 3.536m0 0L9.768 17.768a2 2
                                                        0 11-2.828-2.828L15.232 5.232z" />
                                                </svg>
                                            </button>
                                            <button
                                                className="btn-db-delete"
                                                onClick={() => openDeleteModal(user)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="icon" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M19 7H5m2 0a2 2 0 012-2h6a2 2 0
                                                        012 2m-2 0V17a2 2 0
                                                        01-2 2H9a2 2 0 01-2-2V7h10z" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="no-users-cell" colSpan={5}>
                                        No users found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    <div className="pagination-buttons">
                        {currentPage > 1 && (
                            <button 
                                onClick={() => paginate(1)} 
                                className="btn-b"
                            >
                                1
                            </button>
                        )}
                        {currentPage > 3 && (
                            <span className="pagination-ellipsis">...</span>
                        )}
                        {currentPage > 2 && (
                            <button 
                                onClick={() => paginate(currentPage - 1)} 
                                className="btn-b"
                            >
                                {currentPage - 1}
                            </button>
                        )}
                        <button 
                            className="btn-a"
                        >
                            {currentPage}
                        </button>
                        {currentPage < Math.ceil(filteredUsers.length / usersPerPage) - 1 && (
                            <button 
                                onClick={() => paginate(currentPage + 1)} 
                                className="btn-b"
                            >
                                {currentPage + 1}
                            </button>
                        )}
                        {currentPage < Math.ceil(filteredUsers.length / usersPerPage) - 2 && (
                            <span className="pagination-ellipsis">...</span>
                        )}
                        {currentPage < Math.ceil(filteredUsers.length / usersPerPage) && (
                            <button 
                                onClick={() => paginate(Math.ceil(filteredUsers.length / usersPerPage))} 
                                className="btn-b"
                            >
                                {Math.ceil(filteredUsers.length / usersPerPage)}
                            </button>
                        )}
                    </div>
                    <div className="pagination-controls">
                        <Form.Control
                            as="select"
                            id="usersPerPage"
                            value={usersPerPage}
                            onChange={(e) => {
                                setUsersPerPage(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={10}>10 / page</option>
                            <option value={20}>20 / page</option>
                            <option value={50}>50 / page</option>
                            <option value={100}>100 / page</option>
                        </Form.Control>
                    </div>
                </div>

                <Modal 
                    show={showAddUserModal} 
                    onHide={() => setShowAddUserModal(false)} 
                    centered
                    size="xl"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton style={{ backgroundColor: '#223150' }}>
                        <Modal.Title><h4 className="h4-a" style={{ color: "white" }}>Add User</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="database-modal-body">
                        <Form>
                            <div className="form-row">
                                <Form.Group controlId="formFirstName" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">First Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newUserData.firstName}
                                        onChange={(e) =>
                                            setNewUserData({ ...newUserData, firstName: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formLastName" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Last Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newUserData.lastName}
                                        onChange={(e) =>
                                            setNewUserData({ ...newUserData, lastName: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-row">
                                <Form.Group controlId="formEmail" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Email:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newUserData.email}
                                        onChange={(e) =>
                                            setNewUserData({ ...newUserData, email: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formMemberNumber" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Member Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newUserData.memberNumber}
                                        onChange={(e) =>
                                            setNewUserData({ ...newUserData, memberNumber: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {isAddingUser ? 
                            <CircleLottie height={30} width={30}/>
                        : (
                            <>
                                <button className="btn btn-edit" variant="primary" onClick={addUser} style={{ height: 'auto' }}>
                                    Add User
                                </button>
                                <button variant="secondary" onClick={() => setShowAddUserModal(false)} className="btn btn-cancel">
                                    Cancel
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal 
                    show={isEditing} 
                    onHide={closeEditModal} 
                    centered
                    size="xl"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton style={{ backgroundColor: '#223150' }}>
                        <Modal.Title><h4 className="h4-a" style={{ color: "white" }}>Edit User</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="database-modal-body">
                        <Form>
                            <div className="form-row">
                                <Form.Group controlId="formEditFirstName" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">First Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editUserData.firstName}
                                        onChange={(e) =>
                                            setEditUserData({ ...editUserData, firstName: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEditLastName" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Last Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editUserData.lastName}
                                        onChange={(e) =>
                                            setEditUserData({ ...editUserData, lastName: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-row">
                                <Form.Group controlId="formEditEmail" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Email:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editUserData.email}
                                        onChange={(e) =>
                                            setEditUserData({ ...editUserData, email: e.target.value })
                                        }
                                        className="admin-create-input"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEditMemberNumber" className="d-flex align-items-center">
                                    <Form.Label className="info-title create-title mr-2">Member Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editUserData.memberNumber}
                                        onChange={(e) =>
                                            setEditUserData({ ...editUserData, memberNumber: e.target.value })
                                        }
                                        className="admin-create-input"
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {isResettingPassword ? (
                            <CircleLottie height={20} width={20} />
                        ) : (
                            <button 
                                className="btn btn-edit" 
                                variant="warning" 
                                onClick={resetPassword}
                            >
                                Reset Password
                            </button>
                        )}
                        <div className="ml-auto">
                            <>
                                {isSavingEdit ? (
                                    <CircleLottie height={20} width={20} />
                                ) : (
                                    <>
                                        <button 
                                            className="btn btn-edit" 
                                            variant="primary" 
                                            onClick={saveEdit}
                                            disabled={isResettingPassword || isSavingEdit}
                                            style={isResettingPassword || isSavingEdit ? { pointerEvents: 'none' } : {}}
                                        >
                                            Save
                                        </button>
                                        <button 
                                            className="btn btn-cancel" 
                                            variant="secondary" 
                                            onClick={closeEditModal}
                                            disabled={isResettingPassword || isSavingEdit}
                                            style={isResettingPassword || isSavingEdit ? { pointerEvents: 'none' } : {}}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal 
                    show={showFileModal} 
                    onHide={closeFileModal} 
                    centered
                    size="m"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton style={{ backgroundColor: '#223150' }}>
                        <Modal.Title><h4 className="h4-a" style={{ color: "white" }}>File Content</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="database-modal-body">
                        <Form.Label className="info-title create-title mr-2">Number of users to import: {countRecords(fileContent)}</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        {isImportingUsers ? (
                            <CircleLottie height={30} width={30} />
                        ) : (
                            <>
                                <button className="btn btn-edit" variant="primary" onClick={handleImportUsers}>
                                    Import Users
                                </button>
                                <button className="btn btn-cancel" variant="secondary" onClick={closeFileModal}>
                                    Close
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal 
                    show={showDeleteModal} 
                    onHide={closeDeleteModal} 
                    centered
                    size="m"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton style={{ backgroundColor: '#223150' }}>
                        <Modal.Title><h4 className="h4-a" style={{ color: "white" }}>Delete User</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="database-modal-body">
                        <Form.Label className="info-title create-title mr-2">Are you sure you want to delete {userToDelete?.firstName} {userToDelete?.lastName}?</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        {isDeletingUser ? (
                            <CircleLottie height={30} width={30} />
                        ) : (
                            <>
                                <button className="btn btn-edit" variant="danger" onClick={deleteUser}>
                                    Delete
                                </button>
                                <button className="btn btn-cancel" variant="secondary" onClick={closeDeleteModal}>
                                    Cancel
                                </button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    );
}
