import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import UserHeader from "../pageOverlay/userHeader";
import HomePage from "../userPages/homePage";
import EmailPage from "../userPages/emailPage";
import FormsPage from "../userPages/formsPage";
import BoxPage from "../userPages/boxPage";
import DatabasePage from "../userPages/databasePage";
import Logout from "../Logout";

import { UserService } from "../../services";


/*
    Renders user header and selected page to DOM
    
    Used as a switcher between different services while keeping header visible
*/

export class UserDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: props.uuid,
      guid: "",
      boxToken: "",
      firstName: "",
      email: "",
      orgName: "",
      rawOrgName: "",
      orgImageLink: "",
      orgLogoLink: "",
      hasImage: false,
      renderChildren: false,
      folderID: "",
    };
    this.attachIntercom = this.attachIntercom.bind(this);
  }

  async componentDidMount() {
    await UserService.getUser(this.state.uuid).then((res) => {
      this.setState({
        firstName: res.data.firstName,
        guid: res.data.guid,
        email: res.data.email,
      });
    });

    await UserService.getOrgName(this.state.guid).then((res) => {
      this.setState({ rawOrgName: res.data.name });

      if (res.data.hasImage === 1) {
        this.setState({
          hasImage: true,
          orgLogoLink:
            "https://digital-range-v2.s3.amazonaws.com/organizationLogos/" +
            res.data.logoUrl,
          orgImageLink:
            "https://digital-range-v2.s3.amazonaws.com/organizationBackgrounds/" +
            res.data.backgroundURL,
        });
      }

      var namePieces = this.state.rawOrgName.split("_");
      var result = "";

      for (var x of namePieces) {
        result = result + x + " ";
      }

      this.setState({
        orgName: result,
        renderChildren: true,
      });
    });

    this.attachIntercom();
  }

  attachIntercom = () => {
    window.Intercom("boot", {
      app_id: "ri83jky5",
      email: this.state.email,
      name: this.state.firstName,
    });
  };

  render() {
    return (
      <Suspense fallback={<h1>Loading...</h1>}>
        {this.state.renderChildren && (
          <div>
            <UserHeader
              uuid={this.state.uuid}
              guid={this.state.guid}
              email={this.state.email}
            />
            <Switch>
              <Route path="/email" exact>
                <EmailPage email={this.state.email} guid={this.state.guid} />
              </Route>
              <Route path="/forms" exact>
                <FormsPage guid={this.state.guid} />
              </Route>
              <Route path="/files" exact>
                <BoxPage orgName={this.state.orgName} />
              </Route>
              <Route path="/database" exact>
                <DatabasePage uuid={this.state.uuid} guid={this.state.guid} email={this.state.email} orgName={this.state.orgName}/>
              </Route>
              <Route path="/Logout" exact>
                <Logout />
              </Route>
              <Route>
                <Redirect to="/" />
                <HomePage
                  name={this.state.firstName}
                  orgName={this.state.orgName}
                  email={this.state.email}
                  uuid={this.state.uuid}
                  guid={this.state.guid}
                  hasImage={this.state.hasImage}
                  orgImageLink={this.state.orgImageLink}
                  orgLogoLink={this.state.orgLogoLink}
                />
              </Route>
            </Switch>
          </div>
        )}
      </Suspense>
    );
  }
}

export default UserDash;
