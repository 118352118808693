import http from "../http-common";

const header = {
  headers: {
    'apiKey': process.env.REACT_APP_API_KEY
  }
}

class DatabaseService {
    addUserDB(data) {
        return http.post("/addUserDB", data, header);
    }

    getUsersDB(data) {  
        return http.get("/getUsersDB", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: data
        });
    }

    updateUserDB(data) {
        return http.post("/updateUserDB", data, header);
    }

    importUsersDB(data) {
        return http.post("/importUsersDB", data, header);
    }

    deleteUserDB(data) {
        return http.delete("/deleteUserDB", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: data
        });
    }

    sendResetPasswordEmailDB(data) {
        return http.post("/sendResetPasswordEmailDB", data, header);
    }
}

export default new DatabaseService();
